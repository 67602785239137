.App {
  text-align: center;
  /*background-color: #f2f4f6;*/
  background-color: #f3f3f3;
  min-height: 100vh;

  justify-content: center;
}

.App-header {
  background-color: #ffffff;
  box-shadow: rgba(19, 47, 169, 0.08) 3px 0px 30px, rgba(27, 27, 43, 0.26) 2px 0px 5px;
}

.card-shadow {
  font-size: 10px;
  line-height: 0.7;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px, rgba(40, 47, 60, 0.2) 0px 0px 0px, rgba(40, 47, 60, 0.2) 0px 1px 1px;
}




.text-engineer-menu {
  font-size: 12.5px;
}

.text-engineer-sub {
  font-size: 13px;
  text-align:right;
  color:black;
}

.card-main {
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 530;
  color:#1f1f1f;
  text-indent: 0em;
  margin: 0;
  border-radius: 2rem;
  min-width: 280px;
  font-size: 13.5px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 25px, rgba(40, 47, 60, 0.2) 0px 4px 10px, rgba(40, 47, 60, 0.2) 0px 3px 10px;
}

.card-body-custom {
  background-color: #e6e6e6a3;
}

.time {
  color: rgb(85, 85, 85);
  text-align: center;
  font-size: 12px;
  font-style: italic;
}

.card-header-button {
  text-align: right;
}

.card-dark-city {
  font-size: 12px;
  font-style: italic
}

.card-dark-isFull{
  text-align: center;
  margin: 0;
}

.card-dark-isFree{
  font-size: 12px;
  font-style: italic
}


.card-dark-message{
  text-align: center;
  font-size: 12px;
  color: rgb(228, 106, 36);
}

.card-dark-body-data{
  float:right;
  text-align:right;
  font-weight: 700;
  color: rgba(73, 73, 73, 0.836)
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-weight: 600;
  color: #555;
  user-select: none;
}

.indicatorLast {
  display: flex;
  align-items: flex-end;
  justify-content: left;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 88%;
  margin: 0 auto;
  font-weight: 600;
  color:rgb(66, 66, 66);
  user-select: none;
  padding-left: 70px;
}

.indicatorStart {
  display: flex;
  align-items: flex-end;
  justify-content: left;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 88%;
  margin: 0 auto;
  font-weight: 600;
  color: rgb(66, 66, 66);
  user-select: none;
  padding-left: 25px;
}

